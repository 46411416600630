.MuiBottomNavigationAction-root.Mui-selected::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 30);
    height: 4px;
    width: 60px;
    border-radius: 2px;
    transform: translateX();
    background-color: #31A7DB;
}