@font-face {
  font-family: "CerebriSans-Bold";
  src: local("CerebriSans"),
    url("./Fonts/CerebriSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "CerebriSans-Light";
  src: local("CerebriSans"),
   url("./Fonts/CerebriSans-Light.ttf") format("truetype");
  }
@font-face {
  font-family: "CerebriSans-Regular";
  src: local("CerebriSans"),
   url("./Fonts/CerebriSans-Regular (1).ttf") format("truetype");
  }
@font-face {
  font-family: "CerebriSans-SemiBold";
  src: local("CerebriSans"),
   url("./Fonts/CerebriSans-SemiBold.ttf") format("truetype");
  }

.font-face-bold {
  font-family: "CerebriSans-Bold";
}
.font-face-light {
  font-family: "CerebriSans-Light";
}
.font-face-regular {
  font-family: "CerebriSans-regular";
}
.font-face-semibold {
  font-family: "CerebriSans-SemiBold";
}


body {
  font-family: "CerebriSans-regular";
}


.image {
  position: relative;
  visibility: visible;
  width: 100%;
 
}

.overlay {
  display: none;
}

.image:hover img{
  visibility: hidden;
}

.image:hover .overlay{
  position:absolute;
  top:0;
  left:0;
	width: 100%;
	height: 100%;
	background: rgba(1, 0, 0, 0.2);
	overflow: auto;
	display: inline-block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}